.bill-type {
    position: absolute;
    top: -10px;
    right: 15%;
    width: 95px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}

.bill-type.pms {
    background: var(--theme-blue);
}

.bill-type.brokerage {
    background: var(--red-color);
}

.bill-type.maintenance {
    background: var(--red-color);
}

.bill-type::before {
    content: '';
    position: absolute;
    top: 5px;
    left: -12px;
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 8px solid #ab3a1d;
    transform: rotate(-220deg);
    z-index: -1;
}

.bill-type.pms::before {
    border-bottom: 8px solid #375e0f;
}

.bill-type.brokerage::before {
    border-bottom: 8px solid #832c2c;
}

.bill-type h5 {
    position: relative;
    top: 1px;
    color: #fff;
    font-size: 0.7rem;
    margin: 0;
    text-transform: uppercase;
}