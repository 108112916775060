section.graphs {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 22px;
    .graph_single{
        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    background: var(--white-color);
    border-radius: 22px;
    padding: 20px;
    .graph_container{
        width: 95% !important;
    }
    }
    .chart_card{
        .title{
            font-size: 22px;
            font-weight: 500;
            color: var(--light-black);
            text-align: center;
        }
    }
}

