.project-filter nav {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 8px 0px 0 0px !important;
  margin-bottom: 6px;
}

.project-filter nav button {
  position: relative;
  width: 100%;
  height: 35px;
  transition: 0.5s;
  background: #fff;
  border: none;
  border-bottom: 2px solid transparent;
}

.project-filter nav button:nth-child(1) {
  border-radius: 8px 0 0 8px;
}

.project-filter nav button:nth-last-child(1) {
  border-radius: 0 8px 8px 0;
}

.project-filter nav button span {
  font-size: 14px;
  font-weight: normal;
  text-transform: capitalize;
}

.project-filter nav button.active {
  border-bottom: 2px solid var(--theme-blue);
  background: var(--theme-blue-bg);
}

.project-filter nav button small {
  position: absolute;
  top: -8px;
  right: 10%;
  width: auto;
  padding: 4px 5px 1px 5px;
  height: 15px;
  font-size: 0.6rem;
  background: var(--theme-blue);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 100%;
}

/* new inline filter css  */
.new_inline {
  .project-filter nav {
    align-items: center;
    background: var(--white-color);
    border: 1px solid var(--theme-blue);
    display: flex;
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 5px;
    margin: 0px;
    padding: 0px !important;
  }

  .project-filter nav button {
    position: relative;
    width: auto;
    height: 32px;
    transition: 0.5s;
    background: #fff;
    border: none;
    border-bottom: none;
    align-items: center;
    color: var(--light-black);
    cursor: pointer;
    display: flex;
    font-size: 15px;
    justify-content: center;
    padding: 5px 15px;
  }

  .project-filter nav button.active {
    color: var(--white-color);
    background: var(--theme-blue);
    border-radius: 0px;
  }
  select.filter-dropdown {
    height: 32px;
    transition: 0.5s;
    background: #fff;
    border: none;
    color: var(--light-black);
    cursor: pointer;
    font-size: 15px;
    padding: 4px 15px;
    width: fit-content;
    &.active {
      color: var(--white-color);
      background: var(--theme-blue);
      border-radius: 0px;
    }
    &:focus {
      outline: none;
    }
    option {
      background: var(--white-color);
      color: var(--light-black);
      &.active {
        color: var(--white-color);
        background: var(--theme-blue);
      }
      &:hover {
        color: var(--white-color);
        background: var(--theme-blue);
        cursor: pointer;
      }
    }
  }
}
.card_filter {
  display: flex;
  gap: 22px;
  .cs_single_big {
    position: relative;
    background: var(--theme-orange);
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .inner {
      gap: 20px;
      width: 100%;
      display: flex;
      align-items: center;
      padding: 15px;
      .left {
        width: 75%;
        .card_upcoming .parent {        
          grid-template-columns: repeat(2, 1fr);
          .child {            
            padding: 5px 8px;

            .left{
              width: 100%;
              .line{
                height: 5px;
              }
            }
        }
      }
        .top {
          font-size: 20px;
          font-weight: 500;
          color: var(--white-color);
          margin-bottom: 5px;
        }
       
      }
      .right {
        width: 15%;
        color: white;
        font-size: 40px;
        font-weight: 700;
      }
    }
    &.total {
      width: 28%;
    }
    &.active.total::after {
      content: "";
      position: absolute;
      bottom: -16px;
      left: 0;
      right: 0;
      margin: auto;
      clip-path: polygon(0 0, 50% 100%, 100% 0);
      width: 17px;
      height: 17px;
      background: var(--theme-orange);
    }
  }

  .cf_single {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
    &::after {
      content: "";
      position: absolute;
      bottom: -17px;
      left: 0;
      right: 0;
      margin: auto;
      clip-path: polygon(0 0, 50% 100%, 100% 0);
      width: 17px;
      height: 17px;
    }
    .inner {
      width: 100%;
    }

    &.active.open::after {
      background: var(--theme-green);
    }
    &.active.working::after {
      background: var(--theme-blue);
    }
    &.active.successful::after {
      background: var(--success-color);
    }
    &.active.dead::after {
      background: red;
    }
    &.open {
      width: 18%;
      box-shadow: 0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05);
      background: var(--white-color);
      border-radius: 22px;
      height: 100%;
      border-bottom: 4px solid var(--theme-green);
      border-left: 4px solid var(--theme-green);
      min-height: 129px;
      .top:after {
        background: var(--theme-green);
      }
      .bottom {
        h6 {
          color: var(--theme-green);
        }
      }
    }
    &.working {
      width: 18%;
      box-shadow: 0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05);
      background: var(--white-color);
      border-radius: 22px;
      height: 100%;
      border-bottom: 4px solid var(--theme-blue);
      min-height: 129px;

      .top:after {
        background: var(--theme-blue);
      }
      .bottom {
        h6 {
          color: var(--theme-blue);
        }
      }
    }
    &.successful {
      width: 18%;
      box-shadow: 0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05);
      background: var(--white-color);
      border-radius: 22px;
      height: 100%;
      border-bottom: 4px solid var(--success-color);
      min-height: 129px;

      .top:after {
        background: var(--success-color);
      }
      .bottom {
        h6 {
          color: var(--success-color);
        }
      }
    }
    &.dead {
      width: 18%;
      box-shadow: 0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05);
      background: var(--white-color);
      border-radius: 22px;
      height: 100%;
      border-bottom: 4px solid red;
      border-right: 4px solid red;
      min-height: 129px;
      .top:after {
        background: red;
      }
      .bottom {
        h6 {
          color: red;
        }
      }
    }
    .top {
      display: grid;
      text-align: center;
      grid-template-columns: repeat(2, 1fr);
      padding: 15px;
      grid-column-gap: 15px;
      position: relative;
      .left {
        text-align: center;
        h5 {
          font-size: 22px;
          font-weight: 500;
          margin-bottom: 1px !important;
        }
        h6 {
          font-weight: 400;
          color: var(--theme-grey);
          font-size: 16px;
        }
      }
      .right {
        text-align: center;
        h5 {
          font-size: 22px;
          font-weight: 500;
          margin-bottom: 1px !important;
        }
        h6 {
          font-weight: 400;
          color: var(--theme-grey);
          font-size: 16px;
        }
      }
    }
    .top:after {
      content: "";
      height: 50%;
      width: 1px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      right: 0;
      margin: auto;
    }
    .bottom {
      text-align: center;
      padding: 15px;
      padding-top: 0px;
    }
  }
}
