.material-symbols-outlined {
    font-variation-settings:
        'FILL' 0,
        'wght' 600,
        'GRAD' 200,
        'opsz' 48
}
.material-symbols-outlined.favorited {
    color: red;
    font-variation-settings:
        'FILL' 1,
        'wght' 600,
        'GRAD' 200,
        'opsz' 48
        
}