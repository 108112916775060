.create-form {
  max-width: 600px;
}

.property-list-add-property {
  position: fixed;
  right: 3%;
  bottom: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  color: #fff;
  background: #EB542E;
  box-shadow: 0 -5px 10px 0 rgba(0, 0, 0, 0.2);
  z-index: 999;
  text-decoration: none;
  cursor: pointer;
}
.property-list-add-property.with_9dot {
  bottom: 95px;
}
.property-list-add-property:hover {
  color: #fff;
  text-decoration: none;
}

.property-list-add-property span {
  font-size: 2rem;
  font-weight: bold;
}

.more-add-options-div {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.95);
  z-index: 9999;
  opacity: 0;
  pointer-events: none;
  transition: 1s;
}

.more-add-options-div.open {
  opacity: 1;
  pointer-events: all;
}

.more-add-options-div .more-add-options-inner-div {
  position: fixed;
  right: 3%;
  bottom: 10%;
  display: flex;
  flex-direction: column-reverse;
}

.more-add-options-div .more-add-options-inner-div .more-add-options-icons {
  position: absolute;
  transform: translate(-100%, -0%);
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding-top: 20px;
  transition: 1s;
}

.more-add-options-div.open .more-add-options-inner-div .more-add-options-icons:nth-child(1) {
  transform: translate(-100%, -0%);
}

.more-add-options-div.open .more-add-options-inner-div .more-add-options-icons:nth-child(2) {
  transform: translate(-100%, -100%);
}

.more-add-options-div.open .more-add-options-inner-div .more-add-options-icons:nth-child(3) {
  transform: translate(-100%, -200%);
}

.more-add-options-div.open .more-add-options-inner-div .more-add-options-icons:nth-child(4) {
  transform: translate(-100%, -300%);
}

.more-add-options-div.open .more-add-options-inner-div .more-add-options-icons:nth-child(5) {
  transform: translateX(-100%) translateY(-400%);
}

.more-add-options-div.open .more-add-options-inner-div .more-add-options-icons:nth-child(6) {
  transform: translateX(-100%) translateY(-500%);
}

.more-add-options-div div .more-add-options-icons {
  cursor: pointer;
}

.more-add-options-div div .more-add-options-icons span {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #EB542E;
  color: #fff;
  text-decoration: none;
}

.more-add-options-div div .more-add-options-icons h1 {
  position: absolute;
  left: 0;
  top: 50%;
  white-space: nowrap;
  transform: translateX(-100%);
  padding-right: 15px;
  font-size: 0.8rem;
  color: #000;
  text-align: center;
  left: -8px;
}

.more-add-options-div div .more-add-options-icons.active span {
  background: var(--theme-blue);
}

.more-add-options-div div .more-add-options-icons.active h1 {
  font-size: 1.1rem;
  font-weight: bolder;
  color: var(--theme-blue);
}

.more-add-options-div div .more-add-options-icons:nth-last-child(1) h1 {
  bottom: -30px;
}


@media only screen and (max-width: 1199px) {
.property-list-add-property {
  bottom: 14%;
}
.property-list-add-property.with_9dot {
  bottom: 23%;
}
}