.pg_agent {
  .filter_loading {
    display: flex;
    min-height: 40vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .filters {
    .left {
      display: flex;
      flex-wrap: wrap;
      gap: 22px;
      align-items: center;
      .state_filter {
        min-width: 300px;
      }
    }
  }
}
