.faq_section_single .section_title {
    text-align: center;
}
.faq_section_single .section_title h3 {
    padding-bottom: 20px !important;
    margin-bottom: 10px !important;
    display: inline-block;
    border-bottom: 1px solid #e9573354;
}
.faq .faq_section_single {
    margin-bottom: 80px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 35px;
    border-radius: 20px;
    max-width: 850px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}
button.accordion-button.collapsed {
    font-weight: 500;
    font-size: 18px;
    color: #303030;
}
.faq .faq_section_single:last-child {
    margin-bottom: 0px;
}
.accordion {
    max-width: 800px;
    margin: 0 auto;
    width: 100%;
}
.accordion .accordion-button:not(.collapsed) {
    color: var(--theme-green);
    background: none;
    box-shadow: none;
    font-weight: 500;
    font-size: 16px;
}
.accordion-body {
    color: grey;
    font-size: 14px;
}
.accordion button:focus:not(:focus-visible) {
    outline: none;
    box-shadow: none;
}
.accordion-item {
    border: none;
}
button.accordion-button {
    border-bottom: 1px solid #e9573354;
}
.accordion-item .accordion-button:focus{
    border-color: #e9573354;
}
.accordion .accordion-button:not(.collapsed) {
    border-bottom: 1px solid var(--theme-green);
}
button.accordion-button {
    border-bottom: 1px solid #e9573354;
    font-weight: 700;
    color: #333333;
    font-size: 20px;
}
.accordion-body p {
    margin-bottom: 10px !important;
}


@media (max-width: 767px) {
    .accordion .accordion-button:not(.collapsed) {       
        font-weight: 500;
        font-size: 16px;
    }
    button.accordion-button.collapsed {
        font-weight: 500;
        font-size: 16px;
    }
    
.faq_section_single .section_title h3 {
    padding-bottom: 12px !important;
    margin-bottom: 5px !important;
}
button.accordion-button {
    padding: 15px;
    font-size: 16px; 
}
.accordion-item .accordion-body {
    padding: 15px;
    font-size: 14px;
}
.faq .faq_section_single {
    margin-bottom: 50px;
    padding: 15px;
}

}