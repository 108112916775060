.image_upload_container {
  display: flex;
  align-items: center;
  gap: 10px;
  .image_preview {
    display: flex;
    align-items: center;
    gap: 10px;
    .image_container {
      width: 90px;
      height: 90px;
      overflow: hidden;
      position: relative;
      border-radius: 5px;
      img {
        width: 100px;
        height: 100px;
        object-fit: cover;
      }
      .trash_icon {
        background: white;
        position: absolute;
        top: 0;
        right: 0;
        width: 22px;
        height: 22px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  label.upload_icon {
    border: 1px dashed grey;
    border-radius: 5px;
    width: 90px;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 2px;
    cursor: pointer;
    h6 {
      font-size: 12px;
      font-weight: 400;
      margin-top: 5px;
      color: var(--light-black);
    }
  }
}

.inspection_modal .modal-content {
  padding-bottom: 0px;
}
.inspection_modal .modal-dialog {
  max-width: 360px;
  margin: auto;
}
.inspection_types {
  margin-top: 14px;
  .it_single {
    display: flex;
    cursor: pointer;
    text-decoration: none;
    margin: 0px -15px;
    padding: 8px 15px;
    color: var(--light-black);
    font-size: 17px;
    font-weight: 500;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid grey;
    h5 {
      color: var(--light-black);
      font-size: 16px;
      margin-bottom: 2px;
      font-weight: 500;
      margin-top: 0;
    }
    h6 {
      font-size: 12px;
      color: var(--theme-grey);
      font-weight: 400;
      margin-top: 0;
      margin-bottom: 0;
    }
    &.disabled {
      opacity: 1;
    }
    img {
      height: 32px;
    }
    &:nth-child(odd) {
      background-color: var(--theme-orange-bg);
    }
    &:nth-child(even) {
      background-color: var(--theme-blue-bg);
    }
  }
}

.add_inspection_form {
  display: flex;
  flex-direction: column;
  gap: 22px;
  .form_field{
    &.filled{
      border: 1px solid rgba(3, 70, 135, 0.22);
    }
    &.notfilled{
      border: 1px solid var(--theme-red);
    }
  .filled{
      border: 1px solid rgba(3, 70, 135, 0.22);
    }
    .notfilled{
      border: 1px solid var(--theme-red);
    }
  }
}
.add_inspection_pg {
  .bill_inspection {
    .bill_fields {
      .id_name {
        border: 1px solid rgb(3 70 135 / 22%);
        padding: 10px;
        border-radius: 5px;
        background: var(--white-color);
        display: flex;
        align-items: center;
        gap: 15px;
        flex-wrap: wrap;
        width: fit-content;
        .idn_single {
          background: #f5f5f5;
          padding: 6px 16px;
          border-radius: 5px;
          width: fit-content;
          h6 {
            font-size: 14px;
            font-weight: 400;
            color: var(--light-black);
          }
          h5 {
            font-size: 16px;
            font-weight: 500;
            color: var(--light-black);
          }
        }
      }
    }
  }
  .inspection_type_buttons {
    align-items: center;
    background: var(--white-color);    
    display: flex;
    gap: 12px;
    width: -moz-fit-content;
    width: 80%;
    border-radius: 5px;
    margin-top: 18px;    
    padding: 0px !important;   
    margin-left: auto;
    margin-right: auto;
    button {
      position: relative;
      border: 2px solid #c4c4c4;
      width: 50%;
      height: 40px;
      transition: 0.5s;
      background: #fff;
     
      align-items: center;
      color: var(--light-black);
      cursor: pointer;
      display: flex;
      font-size: 15px;
      justify-content: center;
      padding: 5px 15px;
      position: relative;
      border-radius: 4px;
      transition: 0.3s;
      img{
        display: none;
      }
      &.active {
        color: var(--theme-blue);        
        border: 2px solid var(--theme-blue);

        font-weight: 500;
        transition: 0.3s;
      }
      &.done{
        img{
          display: block;
          height: 20px;
          position: absolute;          
          left: 0;
          right: 0;
          margin: auto;
          top: -11px;
          background: white;
          padding: 0px 5px;
        }
      }
    }
  }
  .room-buttons {
    margin-top: 22px;
    display: flex;
    align-items: center;
    gap: 22px;
    flex-wrap: wrap;
    .room-button {
      padding: 15px;
      border-radius: 8px;
      width: fit-content;
      min-width: 140px;
      min-height: 140px;
      position: relative;
      overflow: hidden;
      .active_hand {
        background: var(--theme-blue);
        border-radius: 50%;
        width: 32px;
        height: 32px;
        display: none;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: -6px;
        right: -6px;
      }
      cursor: pointer;
      border: 2px solid transparent;
      background: white;
      box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      justify-content: center;
      text-align: center;
      .btn_icon {
        border: 2px solid var(--theme-blue);
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
        .bi_icon.half {
          display: none;
        }
        .bi_icon.full {
          display: none;
        }      
        .bi_icon.notallowed {
          display: none;
        }
      }
      .btn_text {
        h6 {
          font-size: 15px;
          font-weight: 500;
          color: var(--theme-blue);
          margin-top: 5px;
          &.half {
            display: none;
          }
          &.full {
            display: none;
          }
          &.notallowed {
            display: none;
          }
        }
      }
      .room_name {
        font-size: 16px;
        font-weight: 500;
        color: var(--light-black);
      }
    }

    .room-button {
      &.active {
        border: 2px solid var(--theme-blue);
        .active_hand {
          display: flex;
        }
      }
      &.full {
        background: #e4ffe4;
        .btn_icon {
          border: 2px solid var(--theme-green2);
          .bi_icon.add {
            display: none;
          }
          .bi_icon.full {
            display: block;
          }
          .bi_icon.half {
            display: none;
          }
          .bi_icon.notallowed {
            display: none;
          }
        }
        .btn_text {
          h6 {
            color: var(--theme-green2);
            &.add {
              display: none;
            }
            &.full {
              display: block;
            }
            &.notallowed {
              display: none;
            }
            &.half {
              display: none;
            }
          }
        }
      }
      &.notallowed {
        background: #ffe5e5;
        .btn_icon {
          border: 2px solid var(--theme-red);
          .bi_icon.add {
            display: none;
          }
          .bi_icon.full {
            display: none;
          }
          .bi_icon.half {
            display: none;
          }
          .bi_icon.notallowed {
            display: block;
          }
        }
        .btn_text {
          h6 {
            color: var(--theme-red);
            &.add {
              display: none;
            }
            &.full {
              display: none;
            }
            &.half {
              display: none;
            }
            &.notallowed {
              display: block;
            }
          }
        }
      }
      &.half {
        background: #fff5d7;
        .btn_icon {
          border: 2px solid var(--theme-inreview);
          .bi_icon.add {
            display: none;
          }
          .bi_icon.half {
            display: block;
          }
          .bi_icon.full {
            display: none;
          }
          .bi_icon.notallowed {
            display: none;
          }
        }
        .btn_text {
          h6 {
            color: var(--theme-inreview);
            &.add {
              display: none;
            }
            &.full {
              display: none;
            }
            &.half {
              display: block;
            }
            &.notallowed {
              display: none;
            }
          }
        }
      }
    }
  }
  .add_and_images {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
  }
  .uploaded_images {
    width: 150px;
    height: 150px;
    overflow: hidden;
    border-radius: 5px;
    img {
      width: 100%;
      height: 100%;

      object-fit: cover;
    }
    .trash_icon {
      position: absolute;
      top: 0;
      right: 0;
      background: white;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .add_icon {
    width: 150px;
    height: 150px;
    border: 1px dashed grey;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    cursor: pointer;
    svg {
      width: 30px !important;
      height: 30px !important;
    }
  }
}
.inspection_report {
  .report {
    .report_header {
      h1 {
        font-size: 25px;
        margin-bottom: 8px;
        font-weight: 500;
        color: var(--theme-blue);
        text-align: center;
      }
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.24),
        0 1px 4px 0 rgba(0, 0, 0, 0.12);
      border-radius: 12px;
      background: white;
      padding: 15px;
      .rh_inner {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 22px;
        align-items: center;
      }
      .i_info {
        display: flex;
        align-items: center;
        gap: 5px;
        img {
          height: 55px;
        }
        h6 {
          color: var(--theme-grey);
          font-size: 16px;
          font-weight: 400;
          line-height: normal;
        }
        h5 {
          color: var(--light-black);
          font-size: 18px;
          font-weight: 500;
          line-height: normal;
        }
      }
      .logo {
        text-align: center;
        img {
          height: 55px;
        }
        .company_address {
          display: flex;
          flex-direction: column;
          gap: 1px;
          color: var(--theme-grey);
          font-size: 12px;
        }
      }
    }
    .detail_card {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 22px;
      align-items: center;
      .dc_single {
        border: 1px solid #dfdfdf;
        border-radius: 10px;
        background: var(--white-color);
        padding: 15px;
        overflow: hidden;
        h2 {
          font-size: 16px;
          color: var(--theme-blue);
          font-weight: 500;
          margin: -15px -15px 6px -15px;
          padding: 6px 15px;
          background: #f2f7ff;
        }
        h5 {
          font-size: 16px;
          color: var(--light-black);
          font-weight: 500;
        }
        h6 {
          font-size: 14px;
          color: var(--theme-grey);
          font-weight: 400;
          margin-top: 1px;
        }
      }
    }
    .room_wise_inspection {
      .rwi_single {
        border: 1px solid #dfdfdf;
        border-radius: 10px;
        background: var(--white-color);

        overflow: hidden;
        margin-top: 22px;
        h2 {
          font-size: 20px;
          color: var(--theme-blue);
          font-weight: 500;
          padding: 10px 15px;
          background: #f2f7ff;
          text-align: center;
          h3{
            font-size: 18px;
            color: var(--theme-red);
            margin-top: 5px;
          }
        }
        .issues {
          .top {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            border-bottom: 1px solid rgb(209 209 209);
            .i_single {
              border-right: 1px solid rgb(209 209 209);

              padding: 15px;
              position: relative;
              &:last-child {
                border-right: none;
              }
              h6 {
                font-size: 17px;
                color: var(--light-black);
                font-weight: 500;
              }
              h5 {
                width: 60px;
                height: 25px;
                border-radius: 5px;
                display: flex;
                align-items: center;

                justify-content: center;
                font-size: 16px;
                margin-top: 3px;
                text-transform: capitalize;
                &.no {
                  background: #e4ffe4;
                  color: var(--theme-green2);
                }
                &.yes {
                  background: #ffe6e6;
                  color: var(--theme-red);
                }
              }
              p {
                font-size: 14px;
                color: var(--theme-grey);
                margin-top: 6px;
                text-transform: capitalize;
              }
              img {
                position: absolute;
                top: 10px;
                right: 18px;
                z-index: 0;
                opacity: 0.3;
                height: 40px;
                width: auto;
              }
            }
          }
          .bottom {
            border-bottom: 1px solid rgb(209, 209, 209);
            padding: 15px;          
            h6 {
              font-size: 18px;
              color: var(--theme-blue);
              font-weight: 500;
            }
            p {
              font-size: 15px;
              color: var(--light-black);
              margin-top: 10px;
            }
          }
        }
        .images_area {
          border-bottom: 1px solid rgb(209, 209, 209);
          padding: 15px;
          h6 {
            font-size: 18px;
            color: var(--theme-blue);
            font-weight: 500;
           
          }
          .img_bunch {
            margin-top: 12px;
            display: flex;
            flex-wrap: wrap;
            gap: 15px;
            img {
              width: 15%;
              aspect-ratio: 3/2;
              border-radius: 5px;
              object-fit: cover;
            }
          }
        }
      }
    }
    .tenant_card {
      border: 1px solid #dfdfdf;
      border-radius: 10px;
      background: var(--white-color);
      overflow: hidden;
      h2 {
        font-size: 17px;
        color: var(--theme-blue);
        font-weight: 500;
        padding: 6px 15px;
        background: #f2f7ff;
        text-align: center;
      }
      .tc_inner {
        padding: 15px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 15px;
      }
      .summary {
        padding: 15px;
        text-align: center;
        font-size: 17px;
        font-weight: 500;
        color: var(--theme-green2);
      }
    }
    .bill_card {
      border: 1px solid #dfdfdf;
      border-radius: 10px;
      background: var(--white-color);
      overflow: hidden;
      h2 {
        font-size: 17px;
        color: var(--theme-blue);
        font-weight: 500;
        padding: 6px 15px;
        background: #f2f7ff;
        text-align: center;
      }
      .bcard_inner {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        align-items: flex-start;
        .bc_single {
          border-right: 1px solid rgb(209, 209, 209);
          padding: 15px;
          position: relative;
          display: flex;
          align-items: flex-end;
          gap: 15px;
          justify-content: space-between;
          &:last-child {
            border-right: none;
          }
          .left {
            h6 {
              font-size: 16px;
              color: var(--light-black);
              font-weight: 500;
            }
            h5 {
              border-radius: 5px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 13px;

              text-transform: capitalize;
              width: fit-content;
              padding: 3px 10px;
              &.unpaid {
                background: #ffe6e6;
                color: var(--theme-red);
              }
              &.paid {
                background: #e4ffe4;
                color: var(--theme-green2);
              }
            }
          }
          .right {
            h4 {
              font-size: 23px;
              font-weight: 600;
              &.paid {
                color: var(--theme-green2);
              }
              &.unpaid {
                color: var(--theme-red);
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1199px) {
  .add_inspection_pg {
    .room-buttons {
      margin-top: 15px;
      align-items: unset;
      gap: unset;
      flex-wrap: unset;
      display: block !important;
      white-space: nowrap;
      overflow: auto;
      .room-button {
        display: inline-block;
        white-space: normal;
        width: 40% !important;
        margin-right: 8px;
        min-width: inherit;
        min-height: inherit;
        padding: 10px 5px;
        .room_name {
          font-size: 14px;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
        .btn_icon {
          width: 35px;
          height: 35px;
        }
        .btn_text {
          h6 {
            font-size: 14px;
            margin-top: 4px;
          }
        }
      }
    }
    .uploaded_images {
      width: 90px;
      height: 90px;
      .trash_icon {
        width: 20px;
        height: 20px;
      }
    }
    .add_icon {
      width: 90px;
      height: 90px;
      svg {
        width: 20px !important;
        height: 20px !important;
      }
    }
  }
}
@media only screen and (max-width: 991px) {
  .add_inspection_pg {
    .title_card.with_title {
      // box-shadow: none;
      // background: none;
      // border-radius: 0px;
      // padding: 0px;
      // border-bottom: none !important;
      h2 {
        color: var(--theme-blue);
      }
      .inspection_type_img {
        display: none;
      }
    }
  }
  // .inspection_report{
  //   .report{
  //     .report_header{
  // h1{
  //   font-size: 20px;

  // }

  //       border-radius: 6px;

  //      padding: 15px 6px;
  //      .rh_inner{
  //       grid-column-gap: 6px;

  //      }
  //       .i_info {
  //     flex-direction: column;
  //         gap: 0px;
  //         align-items: self-start;
  //         img{
  //           height: 30px;
  //         }
  //         h6{
  //           font-size: 12px;
  //         }
  //         h5{
  //           font-size: 14px;
  //         }
  //     }
  //     .logo{
  //       text-align: center;
  //       img{
  //         height: 55px;
  //       }
  //       .company_address{
  //         display: flex;
  //         flex-direction: column;
  //         gap: 1px;
  //         color: var(--theme-grey);
  //         font-size: 12px;
  //       }
  //     }
  //     }
  //     .detail_card{
  //       display: grid;
  //       grid-template-columns: repeat(3,1fr);
  //       grid-column-gap: 22px;
  //       align-items: center;
  //       .dc_single {
  //         border: 1px solid #dfdfdf;
  //         border-radius: 10px;
  //         background: var(--white-color);
  //         padding: 15px;
  //         overflow: hidden;
  //         h2{

  //           font-size: 16px;
  //           color: var(--theme-blue);
  //           font-weight: 500;
  //           margin: -15px -15px 6px -15px;
  //           padding: 6px 15px;
  //           background: #f2f7ff;

  //         }
  //         h5{
  //           font-size: 16px;
  //           color: var(--light-black);
  //           font-weight: 500;
  //         }
  //         h6{
  //           font-size: 14px;
  //           color: var(--theme-grey);
  //           font-weight: 400;
  //           margin-top: 1px;
  //         }
  //     }
  //     }
  //     .room_wise_inspection{
  //       .rwi_single{
  //         border: 1px solid #dfdfdf;
  //     border-radius: 10px;
  //     background: var(--white-color);

  //     overflow: hidden;
  //     margin-top: 22px;
  //     h2{
  //    font-size: 18px;
  //       color: var(--light-black);
  //       font-weight: 500;
  //       padding: 6px 15px;
  //       background: rgb(239, 239, 239);
  //       text-align: center;

  //     }
  //     .issues{
  //       .top{
  //         display: grid;
  //         grid-template-columns: repeat(4,1fr);
  //      border-bottom: 1px solid rgb(209 209 209);
  //      .i_single{
  //       border-right: 1px solid rgb(209 209 209);
  //       text-align: center;
  //       padding: 15px;
  //       position: relative;
  //       &:last-child{
  //         border-right: none;
  //       }
  //       h6{
  //         font-size: 17px;
  //         color: var(--light-black);
  //         font-weight: 500;
  //       }
  //       h5{
  //         width: 60px;
  //         height: 25px;
  //         border-radius: 5px;
  //         display: flex;
  //         align-items: center;

  //         justify-content: center;
  //         font-size: 16px;
  //         margin: auto;
  //         margin-top: 6px;
  //         text-transform: capitalize;
  //         &.no{
  //           background: #e4ffe4;
  //           color: var(--theme-green2);
  //         }
  //         &.yes{
  //           background: #ffe6e6;
  //           color: var(--theme-red);
  //         }
  //       }
  //       p{
  //         font-size: 15px;
  //         color: var(--light-black);
  //         margin-top: 10px;
  //       }
  //       img{
  //         position: absolute;
  //         top: 10px;
  //         left: 18px;
  //         z-index: 0;
  //         opacity: 0.3;
  //         height: 55px;
  //         width: auto;
  //       }
  //      }
  //       }
  //       .bottom{
  //         border-bottom: 1px solid rgb(209, 209, 209);
  //         padding: 15px;
  //         text-align: center;
  //         h6{
  //           font-size: 17px;
  //           color: var(--light-black);
  //           font-weight: 500;
  //         }
  //         p{
  //           font-size: 15px;
  //           color: var(--light-black);
  //           margin-top: 10px;
  //         }
  //       }

  //     }
  //     .images_area{
  //       border-bottom: 1px solid rgb(209, 209, 209);
  //       padding: 15px;
  //       h6{
  //         font-size: 17px;
  //         color: var(--light-black);
  //         font-weight: 500;
  //         text-align: center;
  //       }
  //       .img_bunch {
  //         margin-top: 12px;
  //         display: flex
  //     ;
  //         flex-wrap: wrap;
  //         gap: 15px;
  //         img{
  //           width: 15%;
  //           aspect-ratio: 3/2;
  //           border-radius: 5px;
  //           object-fit: cover;

  //         }
  //     }
  //     }
  //       }
  //     }
  //   }
  // }
}
@media only screen and (max-width: 575px) {
  .add_inspection_pg {
   
    .inspection_type_buttons {     
      width: 100%;
     
    }
  }
}
