.create-form {
    max-width: 600px;
}

.residential-commercial-switch {
    display: flex;
    align-items: center;
    position: relative;
    top: 5px;
}

.residential-commercial-switch span {
    font-size: 15px;
    opacity: 0.5;
    transform: 0.5s;
}

.residential-commercial-switch span.active {
    opacity: 1;
    font-size: 18px;
}

.toggle-switch.off.residential div {
    border: 1px solid var(--theme-blue);
    background: #efefef;
}

.toggle-switch.on.commercial div {
    border: 1px solid var(--theme-orange);
    background: #efefef;
}

.toggle-switch.off.residential div div {
    left: 6%;
    /* background: #59981A; */
    background: var(--theme-blue);
}

.toggle-switch.on.commercial div div {
    background: var(--theme-orange);
    left: calc(94% - 16px);
}

.details-radio {
    position: relative;
    top: -5px;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.details-radio-inner {
    width: 300px;
}

@media (max-width: 500px) {
    .details-radio-inner {
        width: 100%;
        padding: 10px 0;
    }
}

.checkbox {
    position: absolute;
    opacity: 0;
}

.checkbox~.checkbox-label {
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.2);
    height: 35px;
    border-radius: 20px;
    padding: 6px 7px;
    font-size: 0.9rem;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.4);
    transition: 0.3s;
}

/* .checkbox~.checkbox-label.property-setup-checkbox {
  background: #fff;
} */

.checkbox:checked~.checkbox-label {
    display: flex;
    align-items: center;
    background: var(--theme-green);
    border: 1px solid var(--theme-green);
    color: #fff;
}

.checkbox~.checkbox-label small {
    position: relative;
    padding-left: 8px;
    padding-left: 25px;
}

.checkbox:checked~.checkbox-label small {
    transition: 0.3s;
    color: #fff;
    font-weight: bolder;
}

.checkbox-label span.material-symbols-outlined {
    position: absolute;
    font-size: 1.4rem;
    padding-right: 8px;
    transition: display .3s;
}

.checkbox-label span.material-symbols-outlined.done {
    transform: translateY(3px);
}

.checkbox~.checkbox-label span.material-symbols-outlined.done {
    display: none;
}

.checkbox~.checkbox-label span.material-symbols-outlined.add {
    display: inline-block;
}

.checkbox:checked~.checkbox-label span.material-symbols-outlined.done {
    display: inline-block;
}

.checkbox:checked~.checkbox-label span.material-symbols-outlined.add {
    display: none;
}

.property-form-border-div {
    padding: 20px;
    border-right: 2px solid #ededed;
}

@media (max-width: 850px) {
    .property-form-border-div {
        padding: 10px 0;
        border: none;
    }
}

.owner-heading {
    font-size: 1.1rem;
    color: var(--theme-blue);
}

.location-search {
    position: relative;
}

.location-search input,
.location-search select,
.location-search textarea {
    background: none;
    outline: none;
    border: none;
    border-bottom: 1px solid var(--lightgrey-color);
    width: 100%;
    height: 40px;
    font-size: 0.8rem;
    color: var(--darkgrey-color);
    font-weight: bold;
    padding: 0 10px;
    -webkit-appearance: none;
    margin: 0;
}

.location-search input:disabled {
    background: #efefef;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.location-search select {
    background: #efefef;
    -webkit-appearance: none;
}

.location-search select option {
    color: #000;
    font-weight: bold;
}

.location-search select option:disabled {
    color: #aaa;
    font-weight: normal;
}

.location-search textarea {
    resize: none;
    height: 100px;
    border: 1px solid var(--lightgrey-color);
    font-size: 1rem;
    padding: 10px 30px 10px 10px;
    border-radius: 10px;
    transition: 0.5s;
}

.location-search textarea::placeholder {
    color: #aaa;
    font-weight: normal;
}

.location-search textarea:focus,
.location-search textarea:valid {
    border: 1px solid var(--theme-blue);
}

.location-search span {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: var(--red-color);
    font-size: 1.5rem;
    pointer-events: none;
}

.location-search .underline {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 1px;
    background: var(--theme-blue);
    transform: scale(0);
    transform-origin: left;
    transition: 0.5s;
}

.location-search input:focus~.underline,
.location-search input:valid~.underline {
    transform: scale(1);
}

.section-btn-div {
    width: calc(100% - -20px);
    background: var(--yellow-color);
    padding: 0 20px;
}

.section-btn {
    display: flex;
    justify-content: space-between;
}

@media (max-width: 850px) {
    .section-btn button {
        width: 100%;
    }

    .section-btn-div {
        background: none;
        padding: 0;
    }
}

.property-detail .accordion {
    background: #4CD7D0;
    transform: translateY(-1px);
    padding: 20px;
    max-width: inherit;
    margin-left: -25px;
    margin-right: -25px;
    width: auto;
}

.property-detail .accordion-button::after {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: 0;
}

.property-detail .accordion button.accordion-button {
    padding: 0px;
    border: none !IMPORTANT;
    display: block;
}

.property-detail .accordion button.accordion-button .inner {
    display: flex;
    justify-content: space-between;
}

.property-detail .accordion .accordion-item {
    background: none;
}

.property-detail .accordion .accordion-button:focus {
    box-shadow: none;
}

.property-detail .accordion button.accordion-button .inner h5 {
    color: black;
    font-weight: 500;
    font-size: 16px;
}

.property-detail .accordion button.accordion-button .inner h6 {
    color: black;
    font-weight: 400;
    font-size: 14px;
    margin-top: 4px !important;
}

.property-detail .accordion button.accordion-button .inner .right {
    text-align: end;
}

.property-detail .accordion-item:last-of-type .accordion-button.collapsed {
    background: none;
}

.property-detail .accordion-body {
    padding: 20px;
    height: calc(100% - 20px);
    background: #fff;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    margin: 12px 0 0 0;
}

.property_setup ul.react-tabs__tab-list {
    display: flex;
    align-items: center;
    justify-content: space-around;
    background: #F8EA8C;
}

.property_setup ul.react-tabs__tab-list li {
    font-size: 16px;
    font-weight: 500;
    color: var(--theme-orange);
    text-transform: uppercase;
    padding: 10px !important;
    width: 33.3333333%;
    text-align: center;
}

.property_setup .react-tabs__tab--selected {
    border-bottom: 3px solid;
}

.property_setup .basic_detail_inner,
.property_setup .map_inner {
    background: white;
    margin-top: 10px;
    padding: 20px 15px;
}

.property_setup .react-tabs__tab-panel--selected {
    margin-top: 10px;
}

.property_setup .col-lg-4.second_col {
    border-right: 2px solid #ededed;
    border-left: 2px solid #ededed;
}

#property_detail_accordion button.accordion-button {
    padding: 14px;
    background: var(--theme-green);
    color: var(--white-color);
    margin-top: 15px;
    font-size: 18px;
    font-weight: 400;
}

#property_detail_accordion .button_title {
    display: flex;
    gap: 10px;
    align-items: center;
}

#property_detail_accordion .accordion-body {
    padding: 15px;
    background: white;
    border: 2px solid var(--theme-green);
    border-top: none;
}


/* new css  */
.more_info_detail .layout_img img {
    width: 100%;
}

.multi_steps .number {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: var(--light-black);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    font-weight: 700;
    margin: auto;
    position: relative;
}

.multi_steps .step_single h6 {
    font-size: 16px;
    color: var(--light-black);
    margin-top: 4px;
}
.multi_steps .step_single.wait h6{
color: #dedede;
}
.multi_steps .wait .number{
    background: #dedede;

}
.multi_steps .step_single{
    text-align: center;
}
.multi_steps {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    position: relative;
}
.multi_steps .progress_bar {
    position: absolute;
    width: 100%;
    height: 2px;
    border-radius: 15px;
    left: 0;
    top: 21px;
    background: #dedede;
}


.multi_steps .progress_bar .fill{
    position: absolute;  
    height: 2px;
    background: var(--light-black);
    border-radius: 15px;
  top: 0;
  
}

@media (max-width: 850px) {
.multi_steps .number {
    width: 32px;
    height: 32px;
    font-size: 17px;

}
.multi_steps .step_single h6 {
    font-size: 14px;   
}
.multi_steps .progress_bar {  
    top: 16px;  
}

}
@media (max-width: 991px) {
.mobile_gap{
    margin-top: 22px;
}
}
@media (max-width: 575px) {
    .mobile_gap{
        margin-top: 15px;
    }
    .title_card h2 {
        font-size: 18px;    
    }
    
    
.short_prop_summary {
    margin-left: -15px !important;
    margin-right: -15px !important;
    box-shadow: none;
    border-bottom: 1px solid var(--theme-blue);
    border-radius: 0px !important;
    padding-right: 32px !important;
}
.short_prop_summary .left {
    align-items: start !important;
    gap: 6px !important;
    flex-direction: column;
}

.expand{
    align-items: center;
    background: #e1e1e1;   
    cursor: pointer;
    display: flex !important;
    height: 100%;
    justify-content: center;   
    padding: 2px 4px;
    position: absolute;
    right: 0;
    top: 0;
}
.expand span{
    color: #606060;
    font-size: 18px;
}
}
