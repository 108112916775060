.pg_referral {
  .add_referral {
    .pg_divide {
      min-height: 75vh;
    }
    .img_part {
      img {
        width: 65%;
      }
    }
    .refer_form {
      box-shadow: 0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05);
      background: var(--white-color);
      border-radius: 22px;
      padding: 22px;
      max-width: 85%;
      .gift_img {
        width: 48px;
        position: absolute;
        top: -40px;
        left: 0;
        right: 0;
        margin: auto;
      }
      h3 {
        font-size: 20px;
        color: var(--light-black);
        text-align: center;
        margin-bottom: 22px;
      }
    }
  }
  .propagent_dashboard_inner {
    .ps_single {
        position: relative;
      &::after {
        content: "";
        position: absolute;
        bottom: -17px;
        left: 0;
        right: 0;
        margin: auto;
        -webkit-clip-path: polygon(0 0, 50% 100%, 100% 0);
        clip-path: polygon(0 0, 50% 100%, 100% 0);
        width: 17px;
        height: 17px;
      }
      &.pending.arrow::after {
        background: var(--theme-purple);
      }
      &.active.arrow::after {
        background: var(--theme-green2);
      }
      &.inactive.arrow::after {
        background: var(--theme-grey);
      }
    }
  }
}
