.chat_page {
  display: flex;
}

.chat_list {
  opacity: 1;
  width: 25%;
  pointer-events: all;
  display: block;
}

.all_tickets_left {
  overflow-y: auto;
  height: calc(100vh - 68px);
}

.search-select span {
  position: absolute;
  top: 50%;
  left: 14px;
  transform: translateY(-50%);
  font-size: 1.1rem;
  color: #666;
  z-index: 9;
}

.all_tickets_left input.input-wrapper {
  min-height: 59px;
  border-top: none;
 border-left: none;
 border-right: none;
}

.chat_page .input-wrapper {
  background-color: #eee;
  width: 100%;
  border-radius: 4px 4px 0 0 !important;
  height: 2.5rem;
  padding: 0 15px 0 45px !important;
  display: flex;
  align-items: center;
  outline: none;
  border-color: var(--bs-orange) !important;
}

.single_ticket.currently_open {
  background: var(--theme-green-bg);
}

.single_ticket {
  display: flex;
  height: 72px;
  background: white;
  align-items: center;
  border-bottom: 1px solid var(--theme-grey);
  cursor: pointer;
  transition: 0.3s;
  position: relative;
}

.single_ticket .left {
  padding: 0px 12px;
}

.single_ticket .img_container {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  overflow: hidden;
}

.single_ticket .img_container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.single_ticket .right {
  flex-grow: 1;
}

.single_ticket .right .time {
  color: #667781;
  font-size: 10px;
  position: absolute;
  top: 3px;
  right: 8px;
}
.create_ticket_form .section_title, .create_ticket_form label {
  color: var(--white-color);
}
.create_ticket_form input:disabled{
  background: #ffffff9c;
}
.single_ticket .right .unread_chat {
  width: 20px;
  height: 20px;
  background: var(--theme-purple);
  border-radius: 50%;
  color: white;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  /* line-height: 0px; */
  position: absolute;
  right: 8px;
  bottom: 5px;
}

.single_ticket .right h5 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 13px;
  color: #303030;
  font-weight: 500;
  padding-right: 50px !important;
}

.single_ticket .right h6 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 11px;
  color: var(--theme-grey);
  padding-right: 33px !important;
}

.chat_window {
  width: 75%;
  padding: 0;
  display: block;
}

.single_ticket_chat {
  position: relative;
  overflow: hidden;
}

.ticket_detail.chat_box {
  height: calc(100vh - 68px);
  position: relative;
}

.chat_box_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  border-bottom: 2px solid var(--theme-orange);
}

.chat_box_header,
.chat_box_footer {
  padding: 6px 12px;
  background: #eeeeee;
}

.chat_box_header .img_and_name {
  display: flex;
  align-items: center;
  gap: 7px;
}

.chat_user_img {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  overflow: hidden;
}

.chat_user_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.chat_box_header .mobile_chat_back_button {
  display: none;
}

.chat_box_header .mobile_chat_back_button span {
  font-size: 1.1rem;
}

.chat_box_header .mobile_chat_back_button small {
  font-size: 1rem;
  font-weight: bold;
}

.chat_box_body {
  padding: 12px;
  height: calc(100vh - 125px);
  overflow-y: auto;
  padding-bottom: 80px;
}

.chat_box_body .my_chat {
  width: 65%;
  margin-left: auto;
}

.my_chat .chat_single {
  background: var(--theme-blue-bg);
  margin-left: auto;
}

.chat_single {
  margin-top: 4px;
  padding: 5px;
  border-radius: 8px;
  font-size: 13px;
  position: relative;
  width: -moz-fit-content;
  width: fit-content;
  padding-bottom: 14px;
  min-width: 120px;
}

.chat_single .time {
  font-size: 9px;
  position: absolute;
  right: 5px;
  bottom: 2px;
  color: var(--theme-grey);
}

.chat_box_body .partner_chat {
  width: 65%;
  margin-right: auto;
}

.partner_chat .chat_single {
  background: var(--theme-orange-bg);
  margin-right: auto;
  position: relative;
  margin-left: 25px;
}

.partner_chat .chat_single h5 {
  font-size: 12px;
  background: #ffddc7;
  margin: -5px -5px 0px -5px !important;
  padding: 5px !important;
  font-weight: 500;
  border-radius: 0px 8px 0px 0px;
}

.chat_single .time {
  font-size: 9px;
  position: absolute;
  right: 5px;
  bottom: 2px;
  color: var(--theme-grey);
}

.partner_chat .chat_single .support_img {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: -25px;
}

.partner_chat .chat_single .support_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.partner_chat .chat_single:after {
  content: '';
  position: absolute;
  width: 8px;
  height: 10px;
  clip-path: polygon(100% 0, 0 0, 100% 100%);
  background: #ffddc7;
  top: 0;
  left: -7px;
}

.chat_box_footer {
  border-top: 2px solid var(--theme-orange);
}

.chat_box_footer input {
  background: transparent;
  border: none;
  padding: 15px;
}

.send_icon {
  position: absolute;
  right: 15px;
  top: 50%;
  width: 42px;
  height: 42px;
  background: #5a10d5;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(-50%);
}

.send_icon span,
.new_ticket span {
  color: white;
}

.chat_box_footer {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
}