
  .iu_card {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    background: var(--white-color);
    border-radius: 22px;
    padding: 20px;
    .iu_card_inner {
      .iuc_img {      
        img {
          width: 140px;
          height: 140px;          
          object-fit: contain;
        }    
      }
      h5 {
        margin-top: 5px !important;
        font-size: 20px;
        color: var(--theme-red);
    }
    h6 {
        margin-top: 3px !important;
        font-size: 16px;
        color: var(--theme-red);
        font-weight: 400;
    }
    .iuc_c {
        margin-top: 40px;
        h5{
            color: var(--theme-grey);
            font-size: 18px;
            font-weight: 500;
            margin-top: 0px;            
        }
        .support_btn{
            display: grid;
            grid-template-columns: repeat(2,1fr);
            gap: 15px;
        }
    }
    }
  }
  @media only screen and (max-width: 575px) {
    .iu_card {        
        .iu_card_inner {
          .iuc_img {      
            img {
              width: 100px;
              height: 100px;         
            
            }    
          }
          h5 {        
            font-size: 19px;       
        }
        h6 {          
            font-size: 15px;          
        }
        .iuc_c {
            margin-top: 40px;
            h5{              
                font-size: 16px;                      
            }
            .support_btn{
                display: flex;
                flex-direction: column-reverse;
              
            }
        }
        }
      }
  }

