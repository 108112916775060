.pg_contact_us section.hero img {    
    object-position: top;
}

.pg_contact_us .banner img {
    width: 100%;
    aspect-ratio: 3/1;
    object-fit: cover;
}
.pg_contact_us .submit_btn{
    text-align: center;
}
.pg_contact_us button.theme_btn {

    border-radius: 10px; 
}
.loc_em_ph_inner {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 40px;
    box-shadow: 0px 0px 10px 0px #ddd;
    margin: 40px 0px;
    display: flex;
    justify-content: space-evenly;
    gap: 15px;
    border-radius: 20px;
    position: relative;
    z-index: 1;
}
.loc_em_ph_inner:after{
    position: absolute;
    content: '';
    background: var(--white-color);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: 0.9;
   
}


.lep_single .icon_div {
    background: var(--theme-orange);
    display: inline-block;
    border-radius: 50%;
    padding: 20px;
    height: 80px;
    width: 80px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.lep_single_address {
    text-transform: capitalize;
    letter-spacing: 1px;
}

.lep_single .icon_div img {
    width: 35px;
}

.icon_div span {
    color: #ffffff;
    padding: 3px;
    font-size: 35px;
}

.lep_single h4 {
    font-size: 22px;
    margin: 10px 0px !important;
    color: #303030;
}

section.form_sec { 
    display: flex;
    flex-wrap: wrap;
}

.success {
    color: #ffffff;
    font-size: 25px;
    font-weight: 600;
    text-align: center;
}

.pg_contact_us h2.banner_heading {
    color: var(--white-color);
}

section.form_sec .left_img {
    width: 40%;
}

section.form_sec .left_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.lep_single {
    text-align: center;
    width: 33%;
}

.lep_single h6 {
    font-size: 16px;
    letter-spacing: 1px;
    color: grey;
    font-weight: 400;
}

section.form_sec .right_form {
    width: 60%;
    padding: 80px;
    background-size: cover;
    background-position: center center;
    position: relative;
    z-index: 1;
}

section.form_sec .right_form::after {
    content: '';
    background: var(--black-color);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    opacity: 0.7;
    z-index: -1;
}

.map_heading {
    margin: 60px auto 30px auto;
    font-weight: 600;
    font-size: 23px;
    position: relative;
}

.map_heading::after {
    content: "";
    position: absolute;
    bottom: -10px;
    /* Adjust this value to control the distance of the line from the heading */
    left: 50%;
    /* Position the line in the center */
    transform: translateX(-50%);
    /* Center the line horizontally */
    width: 85px;
    /* Adjust the width of the line */
    height: 3px;
    /* Adjust the height of the line */
    background-color: var(--pink);
    /* Adjust the line color */
}





@media only screen and (max-width: 767px) {
    section.loc_em_ph .container {
        max-width: 100%;
    }

    .pg_contact_us .banner img {
        aspect-ratio: unset;
        height: 255px;
        object-fit: fill;
        height: 220px;
        margin-top: 97px;
    }

    .loc_em_ph_inner {
        display: grid;
        justify-content: inherit;
        padding: 15px;
        align-items: left;
    }

    .lep_single {
        width: 100%;
    }

    .lep_single .icon_div {
        padding: 0px;
        width: 34px;
        height: 34px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
    }

    .lep_single h4 {
        font-size: 20px;
        margin: 5px 0px;
    }

    .lep_single .icon_div img {
        width: 20px;
    }

    .icon_div span {
        font-size: 27px;
    }

    .lep_single_address {
        /* width: 250px; */
    }

    section.form_sec .left_img,
    section.form_sec .right_form {
        width: 100%;
    }

    section.form_sec .right_form {
        padding: 25px 15px;
    }  
}
