.qa_menu{   
    display: block !important;
    white-space: nowrap;
    overflow: auto;
    
    .qam_single{
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border-radius: 6px;
        background: var(--white-color);
        padding: 8px;
        cursor: pointer;       
        margin-top: 25px;
        width: fit-content;
        color: var(--light-black);
        min-width: 125px;
        display: inline-block;
        white-space: normal;        
        margin-right: 20px;
        h5{
            font-size: 16px;
            font-weight: 400;
            margin-top: 5px;
            letter-spacing: 0.5px;
            color: var(--theme-green);
            text-align: center;
        }

        .icon{
            text-align: center;
            img{
                width: auto;
                height: 50px;
                margin-top: -32px;
         
            }
             
                 
                 
            
        }
    }
}
@media only screen and (max-width: 767px) {
    .qa_menu{       
        
        .qam_single{              
            margin-top: 15px;                  
            margin-right: 15px;
            min-width: 100px;
            h5{
                font-size: 14px;              
                margin-top: -3px;           
            }
    
            .icon{
                text-align: center;
                img{                   
                    height: 35px;
                    margin-top: -35px;
             
                }
                 
                     
                     
                
            }
        }
    }
  }