.pd_single .pcs_inner {
  width: 100%;
  padding: 16px;
  display: flex;
  gap: 16px;
}

.pd_single .pcs_inner .pcs_image_area {
  width: 40%;
}

.pd_single .pcs_inner .pcs_image_area .default_prop_img {
  width: 100%;
  aspect-ratio: 3/2;
  border-radius: 16px;
}
.p_info_single {
  display: flex;
  gap: 7px;
  align-items: center; 
}
.p_info_single img {
  height: 16px !important;
  width: auto !important;
  opacity: 0.7;
}
.p_info_single h6 {
  font-size: 16px;
  font-weight: 400;
  color: var(--theme-grey);
}
.p_info_single h5 {
  font-size: 16px;
  font-weight: 500;
  color:var(--light-black);
}
.p_info {  
  display: flex;
  justify-content: space-between;
  gap: 15px;
  flex-wrap: wrap;
}
.pd_single .upload_prop_img {
  position: absolute;
  top: 15px;
  right: 15px;
}

.pd_single .upload_prop_img img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.pd_single .pcs_inner .pcs_main_detail {
  width: 60%;
}

.small_images {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 8px;
  gap: 8px;
  overflow: hidden;
  border-radius: 0px 0px 12px 12px;
}

.small_image_single {
  width: 100%;
  aspect-ratio: 3/2;
}

.small_image_single img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* .pd_single .bigimage_container {
    width: 100%;
    aspect-ratio: 1/0.5;
    border-radius: 12px 12px 0px 0px;
    overflow: hidden;
} */
.pd_single .pcs_inner .pcs_image_area img.bigimage {
  border-radius: 0px;
}

.pd_single .property_card_single {
  margin-bottom: 16px;
}

.pd_single .more_detail_card_inner {
  padding: 16px;
}

.p_info_single .pd_icon img {
  height: 32px !important;
  width: auto !important;
  opacity: 1 !important;
}

.pd_single .property_card_single {
  display: inherit;
}

.pd_single .p_info {
  column-gap: 15px;
  row-gap: 35px;
  justify-content: left;
}

.pd_single .p_info_single {
  width: 19%;
}
.pd_single .p_info_single.visiting_days {
  width: 50%;
}
.pdms_single h4 span.currency {
  padding-right: 2px;
}

.pd_single .pp_sidebar {
  margin-top: 0px;
}

.back_btn {
  display: flex;
  gap: 4px;
  color: black;
  background: white;
  padding: 10px 20px;
  border-radius: 30px;
}

.pdms_single h4 span.price {
  font-size: 16px;
  vertical-align: bottom;
  margin-left: 2px;
}

.pmd_section2 .pdms_single h4 {
  font-size: 18px;
  color: #292929;
}
.pmd_section2 .pdms_single h4 .extra {
  color: #999;
  font-size: 14px;
  font-weight: 400;
}

.pmd_section2 .pdms_single h6 {
  color: #999;
  font-size: 12px;
  font-weight: 400;
}

.pmd_section2 .pdms_single {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.pmd_section2.pmd_section3 .pdms_single h4 {
  display: flex;
  align-items: baseline;
  font-size: 18px;
}

.pd_single .pmd_top .property_name {
  font-size: 22px;
}
.pd_single .pmd_top .padd_right{
  padding-right: 200px;
}
.pd_single .pmd_top .property_location {
  font-size: 18px;
}

.pmd_section2.pmd_section3 .pdms_single h4 img {
  height: 22px;
  margin-right: 4px;
}

.pmd_section4 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;
}

.property_full_address h3 {
  font-size: 14px;
  font-weight: 500;
  color: #7e7e7e;
}

.property_full_address h2.card_title {
  margin-bottom: 0px !important;
}

.property_connected_people .single_user .left {
  border-right: none;
  padding-right: 2px;
}

.userlist.property_owners {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  gap: 30px;
}

.userlist.property_owners .single_user {
  border: 1px solid;
  border-radius: 15px;
  border-color: #e7e7e7;
  position: relative;
}

.userlist.property_owners .single_user .property_people_designation {
  position: absolute;
  top: -15px;
  right: 60px;
  font-size: 12px;
  padding: 3px 10px;
}

.oneline_parent::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.userlist.property_owners .single_user .property_people_designation:after {
  content: "";
  position: absolute;
  height: 50%;
  background: #f3f3f3;
  width: 10px;
  top: 2px;
  left: -5px;
  transform: rotate(45deg);
}

.tc_single .property_people_designation {
  position: absolute;
  top: -13px;
  right: 14px;
  font-size: 12px;
  padding: 3px 10px;
}

.property_user .tc_single .property_people_designation {
  right: inherit;
  border: 1px solid #dddddd;
  left: 25px;
  z-index: 9;
  font-size: 10px;
  padding: 1px 10px;
}

.property_user .tc_single .property_people_designation:after {
  /* background: #e7ffe8; */
  border-left: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
}

.tc_single .property_people_designation:after {
  content: "";
  position: absolute;
  height: 50%;
  background: #f3f3f3;
  width: 10px;
  top: 2px;
  left: -5px;
  transform: rotate(45deg);
}

.property_connected_people.userlist .single_user {
  padding: 0px;
}

.pcp_single .contacts_single .icon span {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ddd;
  border-radius: 10px;
  color: var(--theme-green);
  font-size: 20px;
  background: #fff;
  margin-left: auto;
  margin-right: auto;
}

.pcp_single .contacts_single .icon img {
  width: 40px;
  margin: 0 auto;
}

.pcp_single .contacts_single {
  text-align: center;
}

.pcp_single .contacts_single h6 {
  color: #666;
  font-weight: 400;
  font-size: 12px;
  margin-top: 4px !important;
}

.pcp_single .contacts {
  display: flex;
  justify-content: space-around;
  padding-top: 15px;
  border-top: 1px solid #e7e7e7;
  margin-top: 15px;
}

.property_connected_people.userlist {
  display: block;
}

.property_people_designation {
  background: #f3f3f3;
  padding: 5px 10px;
  font-size: 16px;
  font-weight: 500;
  color: #303030;
  margin-bottom: 15px;
  margin-left: -15px;
  margin-right: -15px;
}

.property_connected_people.userlist .pcp_single {
  padding: 0px 15px 15px 15px;
  margin-top: 20px;
  border: 1px solid #e7e7e7;
  border-radius: 15px;
  overflow: hidden;
  /* cursor: -webkit-grab; */
}

.pd_single .p_info_single h6 {
  font-size: 12px;
  line-height: normal;
  margin-top: 0px;
  text-transform: capitalize;
}

.pd_single .p_info_single h5 {
  line-height: normal;
  font-size: 14px;
}

.pd_single .p_info_single {
  gap: 8px;
}

.extra_info_card_property {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.24), 0 1px 4px 0 rgba(0, 0, 0, 0.12);
  border-radius: 12px;
  background: white;
  padding: 16px;
  margin-bottom: 16px;
}

.eicp_single {
  /* box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1); */
  background: #f3f3f3;
  border-radius: 16px;
  padding: 16px;
  text-align: center;
}
.coming_soon {
  position: relative;
}
.coming_soon:after {
  content: "Coming Soon";
  position: absolute;
  top: 0;
  left: 0;
  background: var(--theme-green-bg);
  border-radius: 16px 0px;
  font-size: 13px;
  padding: 4px 8px;
  z-index: 2;
}
.coming_soon:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  border-radius: 16px;
  opacity: 0.6;
  z-index: 1;
}
.coming_soon.r0::after {
  left: inherit;
  right: 0;
}
.eicp_single .icon span {
  color: var(--theme-blue);
  font-size: 28px;
}

.eicp_single .text h6 {
  font-size: 20px;
  color: var(--theme-blue);
  font-weight: 500;
}

.eicp_single .text h5 {
  font-size: 16px;
  color: #303030;
  font-weight: 400;
}

.aai_form .theme_radio_container {
  padding: 0px;
  padding-left: 0px;
  border: none;
}
.aai_form .form_field .field_icon.icon_left {
  right: 8px;
  left: inherit;
}
.aai_form .form_field .field_icon.icon_left span {
  color: var(--theme-grey);
}
.aai_form .form_field input,
.aai_form .form_field textarea {
  border-radius: 8px;
  font-size: 16px;
  padding: 6px;
  width: fit-content;
  color: var(--light-black);
}
.aai_form .form_field.with_icon input {
  padding-right: 36px;
}
.aai_form .form_field.price_input input {
  padding-left: 22px !important;
}
.aai_form .form_field.price_input::after {
  left: 6px !important;
}
.aai_form .form_field input:focus,
.aai_form .form_field textarea:focus {
  outline: none;
  border-color: #498fff;
}

.aai_form .form_field {
  width: fit-content;
}

.aai_form .add_info_text {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: end;
}

.aai_form .addmore {
  color: var(--theme-green);
  font-size: 13px;
  text-transform: capitalize;
  text-decoration: underline;
  cursor: pointer;
  width: fit-content;
}

.aai_form .close_field {
  position: absolute;
  color: red;
  top: -7px;
  right: -4px;
  background: white;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  border: 1px solid #d9d9d9;
  line-height: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
}

.ai_detail_show .left {
  width: 35%;
}

.ai_detail_show {
  box-shadow: none;
  display: flex;
  border-radius: 12px;
  overflow: hidden;
  background: var(--white-color);
  border: 1px solid #dfdfdf;
}

.ai_detail_show .left img {
  width: 100%;
  height: 100%;
  aspect-ratio: 3/2;
  object-fit: cover;
}

.ai_detail_show .right {
  width: 65%;
  padding: 8px;
}

.ai_detail_show .right h5 {
  color: #303030;
  font-weight: 600;
  font-size: 16px;
  text-transform: capitalize;
}

.ai_detail_show .right .in_single {
  background: #dfdfdf;
  border-radius: 30px;
  font-size: 12px;
  margin-top: 2px;
  padding: 4px 12px;
  text-transform: capitalize;
  width: fit-content;
  height: fit-content;
}

.ai_detail_show .in_detail {
  margin-top: 6px;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  height: 66px;
  overflow: hidden;
}

/* tenant css start  */
.tc_single {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background: var(--white-color);
  border-radius: 15px;
  padding: 14px;
  width: fit-content;
  width: 25%;
}

.all_tenants .tc_single {
  display: flex;
  gap: 10px;
  box-shadow: none;
  border: 1px solid #dfdfdf;
  justify-content: space-between;
  width: 100%;
  margin-top: 1px;
  border-radius: 10px;
}

.property_user .all_tenants .tc_single {
  margin-top: 15px;
}

.all_tenants .tc_single .wha_call_icon {
  display: flex;
  border-top: none;
  margin-left: 0px;
  margin-right: -10px;
  margin-bottom: -10px;
  padding: 0px;
  margin-top: -10px;
  position: relative;
  flex-direction: column;
  /* padding-left: 10px;
  padding-right: 10px; */
  border-left: 1px solid #dfdfdf;
  width: 15%;
  /* z-index: 2; */
}

.all_tenants .tc_single .wha_call_icon:after {
  top: 50%;
  width: 100%;
  height: 1px;
  transform: translateY(-50%);
  background: #dfdfdf;
}

.all_tenants .tc_single .wha_call_icon .wc_single {
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.all_tenants .tc_single .wha_call_icon.final_submit .call_icon.wc_single{
  height: 100%;
}

.all_tenants .tc_single .wha_call_icon .wc_single img {
  width: 19px;
}

.all_tenants .tc_single .left {
  display: flex;
  gap: 12px;
  width: 85%;
  align-items: center;
}

.all_tenants .tcs_img_container {
  width: 25%;
  min-width: 60px;
  height: auto;
  aspect-ratio: 1/1;
  margin: inherit;
}

.all_tenants .tc_single .tenant_detail {
  display: flex;
  flex-direction: column;
  gap: 5px !important;
  width: 75%;
}
.all_tenants.inspection_card .tc_single .tenant_detail{
  width: 100%;
}

.all_tenants .tc_single .tenant_detail .t_name {
  text-align: inherit;
  margin: 0px;
}
.all_tenants .tc_single .tenant_detail .i_areas{
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
.all_tenants .tc_single .tenant_detail .i_areas span{
  background: #dfdfdf;
  border-radius: 30px;
  font-size: 12px;
  margin-top: 2px;
  padding: 4px 12px;
}

.all_tenants .tc_single .tenant_detail .t_number {
  text-align: inherit;
  margin: 0px;
  word-break: break-all;
}

.tc_single.t_inactive {
  filter: grayscale(100%);
  opacity: 0.7;
  background: #efefef;
}

.tenant_detail_pg .tc_single {
  width: 100%;
}

.tc_single .tenant_detail {
  display: flex;
  flex-direction: column;
}

.tenant_detail_pg .tc_single .tenant_detail {
  gap: 2px;
}

.tenant_detail_pg .edit_field {
  border: 1px solid #e5e5e5;
  padding: 10px 15px;
  border-radius: 5px;
  margin: 8px 0px;
}

.tenant_detail_pg .tcs_single {
  display: flex;
  gap: 12px;
  padding: 10px;
  padding-bottom: 0px;
}
.tenant_detail_pg .tcs_single .active {
  color: var(--success-color);
}
.tenant_detail_pg .tcs_single .inactive {
  color: var(--theme-red);
}
.tenant_detail_pg .tcs_single h5 {
  color: #7d7d7d;
  font-size: 16px;
  font-weight: 400;
  width: 30%;
}

.tenant_detail_pg .tcs_single h6 {
  width: 70%;
  font-size: 16px;
  font-weight: 500;
  color: var(--light-black);
}

.tenant_detail_pg .edit_field span {
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
}

.tenant_detail_pg .edit_field span.cancel_btn {
  color: var(--theme-red);
}

.tenant_detail_pg .edit_field input {
  border: none;
  border-bottom: 1px solid #e9e9e9;
  margin-bottom: 15px;
  padding: 0px 10px 3px 10px;
  width: 100%;
}
.tenant_detail_pg .edit_field input:focus {
  box-shadow: none;
}

.tenant_detail_pg .theme_radio_new .theme_radio_container {
  padding-left: 0px;
  border: none;
}

.delete_bottom {
  display: flex;
  width: fit-content;
  margin: auto;
  gap: 4px;
  color: #fa6262;
  opacity: 0.9;
  font-size: 14px;
  cursor: pointer;
  align-items: center;
}

.delete_bottom .material-symbols-outlined {
  font-size: 18px;
}

.tenant_detail_pg .edit_field input:focus {
  outline: none;
  border-color: var(--theme-blue);
}

.tenant_detail_pg .edit_field span.save_btn {
  color: var(--theme-green);
}

label.upload_img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  z-index: 0;
  cursor: pointer;
}
.tenant_detail_pg label.upload_img {
  position: absolute;
  bottom: 0;
  right: 10px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  z-index: 1;
  cursor: pointer;
  background: var(--theme-green);
  color: white;
  left: inherit;
  top: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tenant_detail_pg label.upload_img span {
  font-size: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.property_docs_pg .card-image.relative {
  width: 100%;
  aspect-ratio: 3 / 2;
}

label.upload_img.by_text {
  width: fit-content;
  bottom: 15px;
  height: fit-content;
  display: inline-block;
  top: inherit;
  background: white;
  padding: 5px 12px;
  border-radius: 6px;
  border: 1px solid var(--theme-green);
  font-size: 13px;
  left: 0;
  right: 0;
  margin: auto;

  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.tcs_img_container .delete_icon {
  color: var(--theme-red);
  position: absolute;
  top: 5px;
  right: 18px;
  font-size: 16px;
  background: white;
  border-radius: 50%;
  padding: 3px;
  cursor: pointer;
  z-index: 2;
}
.delete_icon_top {
  color: var(--theme-red);
  position: absolute;
  top: -10px;
  right: -3px;
  font-size: 16px;
  background: #ffe5e5;
  border-radius: 50%;
  padding: 3px;
  cursor: pointer;
  z-index: 2;
}

label.upload_img input {
  display: none;
}

.tenant_detail_pg .tcs_img_container {
  width: 120px;
  height: 120px;
  margin-bottom: 6px;
}

.tcs_img_container {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  position: relative;
  margin: auto;
  border: 1px solid #e5e5e5;
}

.tcs_img_container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.tcs_img_container .loader {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.upload_tenant_img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.upload_tenant_img label {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
}

.upload_tenant_img label input {
  display: none;
}

.tenant_detail input {
  border: none;
  padding: 0px;
  margin: 0px;
  width: 100%;
  min-width: 100%;
  text-align: center;
}

.tenant_detail .t_name {
  font-size: 16px;
  font-weight: 500;
  color: var(--light-black);
  text-align: center;
  margin-top: 4px;
  margin-bottom: 6px;
}

.tenant_detail_pg .t_name {
  font-size: 18px;
}

/* Hide border when input is read-only and focused */
.edit_inputs input[readonly]:focus,
.edit_inputs input[readonly]:focus-visible {
  border: none !important;
  outline: none !important;
}

.tenant_detail .t_number {
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  color: #7d7d7d;
}

.tenant_detail_pg .tenant_detail .t_number {
  font-size: 16px;
}

.tc_single .wha_call_icon {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  border-top: 1px solid #e5e5e5;
  margin-left: -8px;
  margin-right: -8px;
  margin-bottom: -8px;
  padding: 6px;
  margin-top: 6px;
  position: relative;
}

.tc_single .wha_call_icon:after {
  content: "";
  background: #e5e5e5;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 1px;
  height: 100%;
}
.inspection_card .tc_single .wha_call_icon.final_submit:after{
  content: none;
}

.owl-carousel .owl-item .wha_call_icon img {
  display: inline-block;
  width: auto;
}

.tc_single .wha_call_icon .wc_single {
  text-align: center;
}

.tc_single .edit_save {
  position: absolute;
  top: 3px;
  right: 8px;
  color: var(--theme-green);
  font-size: 13px;
  text-transform: capitalize;
  text-decoration: underline;
  cursor: pointer;
}

.tenant_detail.td_edit input {
  border-bottom: 1px solid #d5d5d5;
  border-radius: 6px;
}

.tenant_detail.td_edit input:focus {
  outline: none !important;
  /* outline-offset: none !important;
    border: none; */
}

/* tenant css end */

.quick_detail_show .premium img {
  width: 110px;
  height: auto;
  object-fit: contain;
  margin: auto;
}

.quick_detail_show .premium h6 {
  font-size: 0.8rem;
  color: #666;
  margin: 8px 0px 12px 0px;
}

.quick_detail_show .premium h5 {
  color: var(--theme-orange);
  font-size: 18px;
  font-weight: 500;
}

.quick_detail_show .qd_single {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
  color: green;
}

.quick_detail_show .qd_single span {
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 48;
}

.quick_detail_show .qd_single h6 {
  font-size: 20px;
  font-weight: 400;
}

.full_width_sec {
  border-radius: 0px;
  box-shadow: none;
  margin-left: -22px;
  margin-right: -22px;
  position: relative;
  padding-left: 50px;
  /* min-height: 88px; */
}

.full_width_sec .verticall_title {
  position: absolute;
  color: var(--white-color);
  height: 100%;
  top: 0;
  left: 0;
  padding: 4px;
  writing-mode: tb;
  text-orientation: mixed;
  transform: rotate(180deg);
  text-align: center;
  letter-spacing: 0.8px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 12px 0px 0px 12px;
}
.top_right_badge {
  writing-mode: tb;
  letter-spacing: 1.3px;  
  color: var(--white-color);
  font-size: 14px;
  font-weight: 500;
  padding: 10px 3px;
  border-radius: 10px 0px 0px 10px;
  position: fixed;
  top: 68px;
  right: 0;
  text-transform: capitalize;
  min-height: 65px;
  text-align: center;
  z-index: 1;
}
.top_right_badge.in-review{
  background: var(--theme-blue);
}
.top_right_badge.active{
  background: var(--theme-green2);
}
.top_right_badge.inactive{
  background: var(--theme-red);
}
.top_right_badge::after {
  content: "";
  position: absolute;  
  left: -11px;
  -webkit-clip-path: polygon(100% 0, 0 50%, 100% 100%);
  clip-path: polygon(100% 0, 0 50%, 100% 100%);
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 12px;
  height: 12px;  
  z-index: 2;
}
.top_right_badge.in-review::after{
  background: var(--theme-blue);
}
.top_right_badge.active::after{
  background: var(--theme-green2);
}
.top_right_badge.inactive::after{
  background: var(--theme-red);
}

.full_width_sec .verticall_title:after {
  content: "";
  position: absolute;
  left: -14px;
  clip-path: polygon(100% 0, 0 50%, 100% 100%);
  top: 50%;
  transform: translateY(-50%);
  width: 15px;
  height: 15px;
}

.full_width_sec.with_orange .verticall_title:after {
  background: var(--theme-orange);
}

.full_width_sec.with_orange .verticall_title {
  background: var(--theme-orange);
}

.full_width_sec.with_orange {
  background: var(--theme-orange-bg);
}

.full_width_sec.with_blue .verticall_title:after {
  background: var(--theme-blue);
}

.full_width_sec.with_blue .verticall_title {
  background: var(--theme-blue);
}

.full_width_sec.with_blue {
  background: var(--theme-blue-light-bg);
}

.property_card_single.quick_detail_show .left {
  width: 100%;
}

.pmd_section2 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
}

.property_card_single .pmd_section4 .left {
  width: inherit;
  display: flex;
  gap: 22px;
}
.property_card_single .pmd_section4 .left .theme_btn{
  min-width: 120px;
}
.short_prop_summary {
  display: block;
  align-items: center;
  border-radius: 18px;
  padding: 12px;
}

.short_prop_summary .left {
  display: flex;
  align-items: center;
  gap: 12px;
}
.pid_badge {
  border-radius: 6px;
  background: #f5f5f5;
  padding: 2px 10px;
  font-size: 15px;
  width: fit-content;
  color: var(--theme-orange);
  font-weight: 500;

}
.badge_and_icons{
  margin-top: 5px;
}
.short_prop_summary .left .detail {
  display: grid;
  gap: 2px;
}

.short_prop_summary .left .detail h6 {
  color: #373737;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0px;
}
.short_prop_summary .left .detail h6.demand {
  margin-top: 2px;
}
.short_prop_summary .left .img img {
  height: 100px;
  aspect-ratio: 3/2;
  border-radius: 12px;
}

.short_prop_summary .card_badge {
  background: var(--theme-blue);
  color: var(--white-color);
  padding: 4px 8px;
  font-size: 12px;
  font-weight: 400;
  border-radius: 7px;
  letter-spacing: 0.5px;
  width: fit-content;
}
.short_prop_summary .card_badge.active {
  background: var(--success-color);
}
.short_prop_summary .card_badge.inactive {
  background: var(--theme-red);
}
@media only screen and (max-width: 991px) {
  .full_card_width {
    padding-right: 0px;
    padding-left: 0px;
    overflow: hidden;
  }

  .full_width_sec {
    margin-left: 0px;
    margin-right: 0px;
    overflow: hidden;
  }

  .property_card_single.mobile_full_card {
    border-radius: 0px;
    border-bottom: 1px solid var(--theme-blue);
    box-shadow: none;
  }

  .pd_single .property_card_single {
    margin-bottom: 15px;
  }

  .bigimage_container .image-gallery-swipe {
    border-radius: 12px;
  }

  .bigimage_container .image-gallery-thumbnails {
    border-radius: 0px 0px 12px 12px !important;
  }

  .bigimage_container .image-gallery-left-nav .image-gallery-svg,
  .bigimage_container .image-gallery-right-nav .image-gallery-svg {
    height: 28px !important;
    width: 28px !important;
    background: #0000007d !important;
  }

  .image-gallery-fullscreen-button .image-gallery-svg,
  .image-gallery-play-button .image-gallery-svg {
    height: 28px !important;
    width: 28px !important;
    background: #0000007d !important;
  }

  .full_width_sec.with_blue {
    border-bottom: 1px solid var(--theme-blue);
  }

  .full_width_sec.with_orange {
    border-bottom: 1px solid var(--theme-orange);
  }

  .extra_info_card_property {
    box-shadow: none;
    border-radius: 0px;
    background: white;
    padding: 15px;
    border-bottom: 1px solid var(--theme-blue);
  }

  .card_upcoming .parent {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 767px) {
  h2.card_title {
    font-size: 16px;
    margin-bottom: 8px !important;
  }
  .pid_badge {
    font-size: 14px;
    margin-top: 3px;
  }
  .full_width_sec .verticall_title {
    padding: 2px;
    font-size: 8px;
    border-radius: 6px 0px 0px 6px;
  }

  .full_width_sec .verticall_title:after {
    left: -10px;
    width: 10px;
    height: 10px;
  }

  .full_width_sec {
    padding-left: 30px;
  }

  .pd_single .full_width_sec .more_detail_card_inner {
    padding: 10px 0px;
  }

  .pd_single .property_card_single {
    width: 100%;
  }

  .pmd_section2 {
    gap: 10px;
  }

  .pmd_section2 .pdms_single h6 {
    font-size: 10px;
  }

  .pmd_section2.pmd_section3 .pdms_single h4 {
    font-size: 13px;
  }

  .pmd_section2.pmd_section3 .pdms_single h4 img {
    height: 15px;
    margin-right: 3px;
  }

  .pd_single .pcs_inner {
    display: block !important;
    padding: 15px;
  }

  .pcs_inner .pcs_image_area {
    width: 100% !important;
  }

  .pd_single .pcs_inner .pcs_main_detail {
    width: 100% !important;
    padding: 0px;
    margin-top: 15px;
  }

  .pd_single .pcs_inner .pcs_main_detail .pmd_top {
    /* padding-right: 35px; */
    gap: 3px;
  }
  .pd_single .pcs_inner .pcs_main_detail .pmd_top    img {
    width: 35px;
    aspect-ratio: 1/1;
  }
  
  .pd_single .pmd_top .property_location {
    font-size: 16px;
  }

  .pmd_section2 .pdms_single h4 {
    font-size: 13px;
  }
  .pmd_section2 .pdms_single h4 .extra {
    font-size: 9px;
  }

  .pd_single .pcs_inner .pcs_main_detail .pmd_top h4 {
    font-size: 18px;
  }

  .pd_single .more_detail_card_inner {
    padding: 15px;
  }

  .quick_detail_show .premium img {
    width: 60px;
  }

  .quick_detail_show .premium h6 {
    font-size: 10px;
    margin: 3px 0px 3px 0px;
  }

  .quick_detail_show .premium h5 {
    font-size: 12px;
  }

  .quick_detail_show .qd_single .material-symbols-outlined {
    font-size: 16px;
  }

  .quick_detail_show .qd_single h6 {
    font-size: 14px;
  }

  .p_info_single .pd_icon img {
    height: 23px !important;
  }

  .pd_single .p_info_single {
    gap: 5px;
  }

  .pd_single .p_info_single h6 {
    font-size: 10px;
  }

  .pd_single .p_info_single h5 {
    font-size: 12px;
  }

  .pd_single .p_info_single {
    width: 31%;
  }

  .pd_single .p_info {
    row-gap: 22px;
  }

  .ai_detail_show .right h5 {
    font-size: 14px;
  }

  .ai_detail_show .right .in_single {
    font-size: 10px;
    padding: 3px 8px;
  }

  .ai_detail_show .in_detail {
    height: 52px;
  }

  .plus_icon .plus_icon_inner {
    width: 35px;
    height: 35px;
  }

  .plus_icon span {
    font-size: 20px;
  }

  .eicp_single {
    border-radius: 12px;
    padding: 10px;
  }

  .eicp_single .icon span {
    font-size: 22px;
  }

  .eicp_single .text h6 {
    font-size: 18px;
  }

  .eicp_single .text h5 {
    font-size: 14px;
  }

  .property-list-add-property {
    width: 38px !important;
    height: 38px !important;
  }

  .property-list-add-property span {
    font-size: 22px !important;
  }

  .more-add-options-div div .more-add-options-icons span {
    width: 38px !important;
    height: 38px !important;
    font-size: 22px !important;
  }
  .tenant_detail_pg .t_name {
    font-size: 16px;
  }
  .tenant_detail_pg .tenant_detail .t_number {
    font-size: 14px;
  }
  .tenant_mobile_full_card {
    padding-left: 0px;
    padding-right: 0px;
  }
  .tenant_mobile_full_card .tc_single {
    box-shadow: none;
    border-radius: 0px;
    border-bottom: 1px solid var(--theme-blue);
  }
  .tenant_detail_pg .tcs_single {
    gap: 8px;
    padding: 3px;
    align-items: center;
  }
  .tenant_detail_pg .tcs_single h5 {
    font-size: 14px;
  }
  .tenant_detail_pg .edit_field input {
    font-size: 14px;
  }
  .tenant_detail_pg .edit_field {
    padding: 6px;
    margin: 4px 0px;
  }
  .tenant_detail_pg .tcs_single h6 {
    font-size: 14px;
  }
  .tenant_detail_pg .my_big_card {
    border-radius: 0px;
    box-shadow: none;
    margin-left: -15px;
    margin-right: -15px;
    border-bottom: 1px solid var(--theme-blue);
    flex-direction: column;
    justify-content: inherit !important;
    align-items: inherit !important;
    gap: 15px;
    text-align: center;
  }
  .tenant_detail_pg .blog_sect .card-body {
    padding: 8px 15px;
  }
}

@media only screen and (max-width: 575px) {
  .delete_bottom {
    font-size: 12px;
  }

  .delete_bottom .material-symbols-outlined {
    font-size: 12px;
  }
  .pd_single .p_info_single {
    width: 47%;
  }
  .pd_single .p_info_single.visiting_days {
    width: 100%;
  }
  .coming_soon:after {
    font-size: 10px;
  }
  .all_tenants .tcs_img_container {
    min-width: inherit;
  }
  .tenant_detail .t_name {
    font-size: 12px;
  }
  .tenant_detail .t_number {
    font-size: 12px;
  }

  .all_tenants .tc_single .tenant_detail {
    gap: 3px !important;
  }
  .text_near_icon {
    font-size: 10px;
    top: 0px;
  }
  .aai_form .form_field input,
  .aai_form .form_field textarea {
    font-size: 14px;
    width: 100%;
  }
  .aai_form .form_field {
    width: 100%;
  }
  .top_right_badge {   
    font-size: 11px;
    font-weight: 400;
    padding: 8px 2px; 
   
  }
  .top_right_badge::after {     
    left: -6px;  
    width: 7px;
    height: 7px;  
 
  }
}
