.property_docs_pg {
  .tab_and_mode {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    gap: 15px;
    flex-wrap: wrap;
    ul.tabs{
        margin-top: 0px !important;
    }
  }
  .blog_sect .card-body {
    padding: 15px;
    text-align: center;
    background: #e8f0ff;
    display: inherit;
  }
  .blog_sect .added_by {
    background: rgb(239, 239, 239);
    margin: 0px -15px -15px -15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    padding: 10px;
    h6 {
      font-size: 14px;
      font-weight: 500;
      color: var(--theme-grey);
    }
    h5 {
      font-size: 16px;
      font-weight: 500;
      color: var(--light-black);
    }
  }
  .blog_sect .card-body h3 {
    font-size: 20px;
  }

  .blog_sect .card-body p {
    font-size: 16px;
    margin-bottom: 0px;
  }

  .learn-more {
    color: var(--theme-red);
    text-transform: capitalize;
  }
}

.add_select_date {
  display: flex;
  gap: 22px;

  .my_big_card {
    background: var(--white-color);
    border-radius: 10px;
    padding: 12px 24px;
    box-shadow: none;
    width: fit-content;
    border: 1px solid #d9d9d9;

    h2.card_title {
      text-align: center;
      margin-bottom: 10px !important;
    }

    .inner {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 12px;

      input,
      select {
        padding: 5px 8px;
        border-radius: 9px;
        border: 1px solid var(--theme-blue);

        &:focus {
          outline: none;
        }
      }
    }

    .theme_btn.btn_fill {
      padding: 5px 8px;
      border-radius: 9px;
    }

    &.selected_date {
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--theme-blue-bg);

      h2.card_title {
        margin-bottom: 0px !important;
      }
    }
  }
}

@media only screen and (max-width: 575px) {
  .property_docs_pg {
    .pg_header {
      flex-direction: column;
      align-items: inherit !important;
      gap: 15px;
      .theme_btn {
        text-align: center;
      }
    }
    .my_big_card.add_doc_form {
      margin-left: -15px;
      margin-right: -15px;
      border-radius: 0px;
      border-bottom: 2px solid var(--theme-blue);
    }
  }
}
